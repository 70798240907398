import { LockedTestableProductFilterModel } from '../../data/models';
import {
  GetLockedTestableProductsUseCase,
  LockedTestableProductListData,
  LockedTestableProductsRepositoryContract,
} from '../types';

export const getLockedTestableProductsUseCase: GetLockedTestableProductsUseCase = async (
  repository: LockedTestableProductsRepositoryContract,
  params: LockedTestableProductFilterModel,
): Promise<LockedTestableProductListData> => {
  return repository.lockedTestableProductsList(params);
};
