import { MerchantTestableProductRelationModel } from '../../data/models';
import {
  GetMerchantTestableProductRelationUseCase,
  LockedTestableProductsRepositoryContract,
} from '../types';

export const getMerchantTestableProductRelationUseCase: GetMerchantTestableProductRelationUseCase =
  async (
    productId: string,
    repository: LockedTestableProductsRepositoryContract,
  ): Promise<MerchantTestableProductRelationModel> => {
    return repository.merchantTestableProductRelation(productId);
  };
