import { resolveLockedTestableProductRepository } from '@di/app';
import { LockedTestableProductFilterModel } from '../../data/models';
import { getLockedTestableProductsUseCase } from '../use-cases/get-locked-testable-products.use-case';
import { getMerchantTestableProductRelationUseCase } from '../use-cases/get-merchant-testable-product-relation';

export class LockedTestableProductsUseCaseResolver {
  public static async LockedTestableProductsList(
    params: LockedTestableProductFilterModel,
  ): ReturnType<typeof getLockedTestableProductsUseCase> {
    return getLockedTestableProductsUseCase(resolveLockedTestableProductRepository(), params);
  }

  public static async merchantTestableProductRelation(
    productId: string,
  ): ReturnType<typeof getMerchantTestableProductRelationUseCase> {
    return getMerchantTestableProductRelationUseCase(
      productId,
      resolveLockedTestableProductRepository(),
    );
  }
}
